//
function sendUsrData(form) {
    //form.style.display = 'none'; // Скрываем кнопку
    document.getElementById('loading-animation').style.display = 'inline-block';
    let formName = form.getAttribute('form');
    let formElement = document.querySelector(`.fancybox-skin [name=${formName}]`)
   sendAjaxData(formElement)
};
//
function sendAjaxData(formElement) {
    let formData = new FormData(formElement);
    let xhr = new XMLHttpRequest();
    xhr.open(formElement.method, formElement.action, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onload = function() {
        if (xhr.status >= 200 && xhr.status < 400) {
            window.location.href = '/my_account.html'
           /* response = JSON.parse(xhr.responseText);
            if (response.redirect) {
                window.location.href = response.redirect;
            }*/
        } else {
            let vc = formElement.querySelector('.invalid-feedback');
            vc.classList.add('invalid-feedback-error')
           // response = JSON.parse(xhr.responseText);
           //vc.children[0].textContent = response.error;
            vc.children[0].textContent = 'Не корректні облікові дані / '+JSON.parse(xhr.responseText).message;
        }
    };
    xhr.onerror = function() {
        let vc = formElement.querySelector('.invalid-feedback');
            vc.classList.add('invalid-feedback-error')
            vc.children[0].textContent = trans('used.error_connection');
    };
    xhr.send(formData);
}

window.sendUsrData = sendUsrData;
